body:not(.home)
  .site-header-redes
    a
      color #4f39be

.site-header-redes
  display none

.site-navigation
  font-family pier-regular
  display none
  .link-menu span
  .cont-item-menu span
    color #4f39be

  .item-menu:not(:last-child)
    padding-right margin-gap

.nav-assistive
  color white

@media (min-width: 770px)
  .site-navigation
    display inline-block
  .nav-assistive
    display none
  .site-header-redes
    display: inline-block;
    width: 150px;
    a
      color #4f39be
    .fa
      margin 0px 8px
      font-size 26px

// AUX NAVIGATION
// -------------------------------------------
.nav-aux-container
  font-size 2rem
  text-transform uppercase
  .navigation-menu
    display block
  .navigation-item
    margin 26px
  .item-link,
  .item-link:hover
    color white
  .button--ghost
    background-color white
  .nav-menu .item-menu
    float none
    margin-bottom .7rem
