@import 'nib'

.Container.container--blue-dark
  background-color blue-dark
  color white

body:not(.logged-in)
  .Container.container--full .site-wrap
    width 100%
    max-width 100%

  .Container.container--bigger .site-wrap
    max-width 1140px

  .Container.container--bigger.contact-form
    .Content, h4
      margin-bottom 0px

  .Container.we-are
  .Container.container--triangle
    .site-wrap
      width 100%
      max-width initial
      position relative
      display flex
      min-height 90vh
      &::before,
      &::after
        content ""
        @extends $absolutize
        z-index 0
      &::before
        background linear-gradient(15deg, blue-dark 35vh, transparent 35vh)
      &::after
        background linear-gradient(-15deg, blue-dark 35vh, transparent 35vh)
    .Content
      position relative
      z-index 1

    @media (min-width: responsive-breakpoint)
      .wrapper
        min-height 60vh

body:not(.logged-in)
  .Container.courses
    position relative
    display block
    height initial

    .Content
      p
        margin 0

    .list-courses
      position relative
      height auto

      .content
        display block
        float none
        width initial
        text-align center
        padding 40px 10px 0px 10px
        .title
          margin-top 10px
          color black

    .image
      height 120px
      width 120px
      border-radius 50%
      border 3px solid blue-light
      display flex
      align-items center
      background-color white
      margin 0 auto

      .image-course
        margin 0 auto

    @media (min-width: responsive-breakpoint)
      .list-courses
        position absolute
        top -70px
        max-width 900px
        margin 0 auto
        left 0
        right 0

        .content
          display inline-block
          float left
          width 25%
          text-align center
          padding 10px

.Container
  .Formulario-container
    max-width 650px
    margin 0 auto
    text-align center

    .button--purple
      padding 0 22px
      border-radius 0px

.Content
  .grid-events
    display block

    .grid-item
      width 100%
      padding 10px

    p
      margin 0px
      line-height 1
      font-size 12px
    h2
      margin 0px
      font-size 1em
    a
      font-size 14px

  @media (min-width: 400px)
    .grid-events
      p
        font-size 16px
      h2
        font-size 1.4em
      a
        font-size 16px

  @media (min-width: responsive-breakpoint)
    .grid-events
      display flex
      align-items center

      .grid-item
        display inline-block
        width 50%

        &:first-child
          width 49%
          border-right 1px solid rgba(white, 0.5)

      h2
        margin-bottom 20px
