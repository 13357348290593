.footer-quick-contact
  color green
  font-size 20px
  p:not(:first-child)
    margin-top 2rem

.footer-about
  margin-top 3rem
  background-color primary
  color white
  padding margin-gap*2 0

  a {color: white}
  a:hover {color: purple}

  .wrap
    padding 20px
    margin 0 auto
    max-width 1200px

  h4
    font-size 1.1em

  .footer-social
    a
      margin 5px 5px
      i
        font-size 48px

  @media (min-width: 680px)
    .wrap
      display flex
      align-items center
      padding 0
    .message
      width 60%

    h4
      font-size 1.25em

    .footer-social
      width 40%
      a
        margin 0 12px

.footer-form
  max-width 600px
  margin margin-gap*2 auto 0
  input
    width 480px
  .button
    flex 1
    border-radius 0

// COLOPHON
// ------------------------------------
.site-colophon
  padding margin-gap
  background-color #0f3558
  color grey
  a
    color grey-lighter
