.Content
  padding 10px 8px 16px // Para separar grid-items
  &.is-medium
    font-size: size-5
    code
      font-size: size-6
  &.is-large
    font-size: size-4
    code
      font-size: size-5
  &:not(:last-child)
    margin-bottom: 20px
  h1,
  h2
    font-family family-pier-bold

  h3,
  h4,
  h5,
  h6
    font-family family-gotham

  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    color: text-strong
    font-weight: 300
    line-height: 1.125
    margin-bottom: 20px
  h1,
  h2,
  h3
    &:not(:first-child)
      margin-top: 40px
  h1
    font-size: 1.6em
  h2
    font-size: 1.4em
  h3
    font-size: 1.2em
  h4
    font-size: 1.1em
  h5
    font-size: 1em

  @media (min-width: responsive-breakpoint)
    h1
      font-size 2em !important
    h2
      font-size 1.75em !important
    h3
      font-size 1.5em !important
    h4
      font-size 1.25em !important
    h5
      font-size 1.125em !important
    h6
      font-size 1em !important

  p:not(:last-child)
    margin-bottom: 1em
  li + li
    margin-top: 0.25em
  ol
    list-style: decimal outside
    margin: 1em 2em
  ul
    list-style: disc outside
    margin: 1em 2em
    ul
      list-style-type: circle
      margin-top: 0.5em
      ul
        list-style-type: square
  blockquote
    background: background
    border-left: 5px solid border
    padding: 1.5em
    &:not(:last-child)
      margin-bottom: 1em

.Message--success
  color #48762b !important

.table--purple,
.table--purple td,
.table--purple th
  border-collapse collapse
  border 2px solid purple
.table--purple td,
.table--purple th
  padding margin-gap*1.5 margin-gap

.agenda-date
  font-size 12px
  text-align center
  line-height 1
  div:nth-child(2)
    font-size size-3
    letter-spacing 3px

.table--centered
  td
    vertical-align middle
    text-align center

.table--agenda
  @extends .table--centered
  tr > td:first-child
    @extends .agenda-date

.image--background
  width 100%
  background-repeat no-repeat
  background-position 50% 20%
  background-size cover
  height 30vw
