@import 'nib'

.testimonial-list
  width 100%

.testimonial-item
  width 100%
  color white
  > .wrapper
    height 650px
    position relative

  &.courses
    .select
      margin: 20px 0

.testimonial-image,
.testimonial-image:after
  @extends $absolutize

.testimonial-image
  background-size: cover;
  background-position: 50%;
  z-index 1
  &:after
    content ''
    transition background-color 200ms ease-in-out
    background-color rgba(black, .25)

.testimonial-content
  position relative
  z-index 2
  padding 20px 10px

  .event-item
    display none

  .event-item.active
    display table-row !important

.testimonial-title
  font-size size-5
  text-transform uppercase
  letter-spacing 2px

.testimonial-resume
  font-size 12px
  font-weight 300

@media (max-width: 619px)
  .select-visible
    display none !important
    &:after
      content ""

// RESPONSIVE
// ---------------------------------------------------
@media (min-width: 450px)
  .testimonial-resume
    font-size 14px

@media (min-width: responsive-breakpoint)
  .testimonial-content
    padding 20px

  .testimonial-resume
    display none

  .testimonial-title
    font-size 19px
    letter-spacing 2px
    transition transform 350ms ease-out
    transform translate3d(0, 10px, 0)
  .testimonial-item
    > .wrapper
      height 400px

    &.courses
      > .wrapper
        height 620px

      .table
        max-width: 450px;
        margin: 0px auto;
        color: #ffffff;

        tr
          height 88px

  .testimonial-item:hover
    .testimonial-image:after
      background-color rgba(black, .5)
    .testimonial-resume
      display block
    .select-visible
      display none !important
      &:after
        content ""
    .testimonial-title
      transform translate3d(0,-8px,0)

@media (min-width: 1090px)
  .testimonial-item
    width 50% !important
