@import 'nib'

.image-logo
  margin 20px auto

.site-header
  background-color #4f39be
  text-transform uppercase

  @media (min-width: 500px)
    background-color rgba(white, .6)
    position absolute
    top 0
    left 0
    right 0
    z-index 3

  &-right
    display flex
    padding-right 2%

.header-logo
  padding margin-gap margin-gap*2
  background-color white
  &-img
    background url(../images/logo-header-2.png) 0 0 no-repeat
    height 60px
    width 62px
    display block
    @media (min-width: 950px)
      background-image url(../images/logo-header.png)
      width 293px

// COVER
// -----------------------------------------------------------
.site-cover
  height 60vw
  width 100%
  @extends $background-image
  background-size 100%
  background-position left top
  position relative
  z-index -1
  // Prevent image background to increase with width over 1000px
  @media (min-width: 1000px)
    background-size cover
    height 95vh
    min-height 400px
  @media (min-width: 500px)
    // Add a top gradient for better contrast in the header
    &:before
      absolute right 0 left 0
      top 0
      height 8vw
      content ''
      display block
      z-index 1
      background linear-gradient(top, black, transparent)
    // Margin magic for logo
    + .site-logo-container
      margin-top -40px

.site-cover-colored
  width 100%
  height 23vw
  background #0e3559
  position relative
  z-index -1

@media (min-width: 500px)
  .site-cover
    // Add a top gradient for better contrast in the header
    &:before
      absolute right 0 left 0
      top 0
      height 8vw
      content ''
      display block
      z-index 1
      background linear-gradient(top, black, transparent)
  .site-cover,
  .site-cover-colored
    // Margin magic for logo
    + .site-logo-container
      margin-top -40px

// JUMBOTRON
// -----------------------------------------------------------
.header-jumbotron
  absolute bottom 13vw left 30px
  max-width 1150px
  color white

.jumbotron-title
  font-family family-pier-bold
  font-size 1.5rem
  line-height 1
@media (min-width: 500px)
  .jumbotron-title
    font-size 2.3rem
@media (min-width: 767px)
  .header-jumbotron
    left 50px
  .jumbotron-title
    font-size 58px
  .jumbotron-description
    font-size size-3
@media (min-width: 1000px)
  .header-jumbotron
    bottom 25vh
  .jumbotron-title
    font-size 90px
  .jumbotron-description
    font-size size-3

.site-cover-colored .header-jumbotron
  bottom 10px

// LOGO
// -----------------------------------------------------------
.site-logo
  background-image url(../images/logo.png)
  @extends $background-image
  width 310px
  height 236px
  display block
  margin 0 auto margin-gap*3.4
