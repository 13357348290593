html
  background body-background
  font-size size-normal
  -moz-osx-font-smoothing grayscale
  -webkit-font-smoothing antialiased
  min-width 300px
  overflow-x hidden
  overflow-y scroll
  text-rendering optimizeLegibility

body,
button,
input,
select,
textarea
  font-family: family-primary

body
  color: text
  font-size: 1rem
  line-height: 1.428571428571429

code,
pre
  -moz-osx-font-smoothing: auto
  -webkit-font-smoothing: auto
  font-family: monospace
  line-height: 1.25

a
  color: link
  cursor: pointer
  text-decoration: none
  transition: none speed easing
  &:hover
    color: link-hover

code
  background: code-background
  color: code
  font-size: 12px
  font-weight: normal
  padding: 1px 2px 2px

hr
  border-top-color: border
  margin: 20px 0

img
  max-height: 100%
  max-width: 100%

input[type="checkbox"],
input[type="radio"]
  vertical-align: baseline

small
  font-size: size-small

strong
  color: text-strong

article,
aside,
figure,
footer,
header,
hgroup,
section
  display: block

pre
  background: pre-background
  color: pre
  white-space: pre
  word-wrap: normal
  code
    background: pre-background
    color: pre
    display: block
    overflow-x: auto
    padding: 16px 20px

table
  width: 100%
  th,
  td
    text-align: left
    vertical-align: top
  th
    color: text-strong

.fa
  font-size: 21px
  text-align: center
  vertical-align: top


.site-wrap
  width 95%
  max-width 1000px
  margin-left auto
  margin-right auto
