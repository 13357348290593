
@import 'nib'
@import '_reset'
@import 'layout/images'
@import '_images-conf'

$background-image
  background-size cover
  background-position center center
  background-repeat no-repeat

$absolutize
  position absolute
  top 0
  right 0
  left 0
  bottom 0

.background-image
  @extends $background-image

body:not(.logged-in)
  .background-image--opaqued
    position relative
    z-index 0
    &:before
      @extends $absolutize
      content ''
      z-index -1
      display block
      background-color rgba(black, .35)
