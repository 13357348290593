/*
Stylus variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon_home_width;
}

The large array-like variables contain all information about a single icon
$icon_home = x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet = width height image $spritesheet_sprites;
*/
$image_crop_name = 'image-crop';
$image_crop_x = 0px;
$image_crop_y = 103px;
$image_crop_offset_x = 0px;
$image_crop_offset_y = -103px;
$image_crop_width = 75px;
$image_crop_height = 75px;
$image_crop_total_width = 468px;
$image_crop_total_height = 178px;
$image_crop_image = '../images/images.png';
$image_crop = 0px 103px 0px -103px 75px 75px 468px 178px '../images/images.png' 'image-crop';
$image_dispensary_name = 'image-dispensary';
$image_dispensary_x = 75px;
$image_dispensary_y = 103px;
$image_dispensary_offset_x = -75px;
$image_dispensary_offset_y = -103px;
$image_dispensary_width = 75px;
$image_dispensary_height = 75px;
$image_dispensary_total_width = 468px;
$image_dispensary_total_height = 178px;
$image_dispensary_image = '../images/images.png';
$image_dispensary = 75px 103px -75px -103px 75px 75px 468px 178px '../images/images.png' 'image-dispensary';
$image_distribution_name = 'image-distribution';
$image_distribution_x = 150px;
$image_distribution_y = 103px;
$image_distribution_offset_x = -150px;
$image_distribution_offset_y = -103px;
$image_distribution_width = 75px;
$image_distribution_height = 75px;
$image_distribution_total_width = 468px;
$image_distribution_total_height = 178px;
$image_distribution_image = '../images/images.png';
$image_distribution = 150px 103px -150px -103px 75px 75px 468px 178px '../images/images.png' 'image-distribution';
$image_logo_name = 'image-logo';
$image_logo_x = 0px;
$image_logo_y = 0px;
$image_logo_offset_x = 0px;
$image_logo_offset_y = 0px;
$image_logo_width = 468px;
$image_logo_height = 103px;
$image_logo_total_width = 468px;
$image_logo_total_height = 178px;
$image_logo_image = '../images/images.png';
$image_logo = 0px 0px 0px 0px 468px 103px 468px 178px '../images/images.png' 'image-logo';
$image_process_name = 'image-process';
$image_process_x = 225px;
$image_process_y = 103px;
$image_process_offset_x = -225px;
$image_process_offset_y = -103px;
$image_process_width = 75px;
$image_process_height = 75px;
$image_process_total_width = 468px;
$image_process_total_height = 178px;
$image_process_image = '../images/images.png';
$image_process = 225px 103px -225px -103px 75px 75px 468px 178px '../images/images.png' 'image-process';
$spritesheet_width = 468px;
$spritesheet_height = 178px;
$spritesheet_image = '../images/images.png';
$spritesheet_sprites = $image_crop $image_dispensary $image_distribution $image_logo $image_process;
$spritesheet = 468px 178px '../images/images.png' $spritesheet_sprites;

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  spriteWidth($icon_home)
}

.icon-email {
  sprite($icon_email)
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
spriteWidth($sprite) {
  width: $sprite[4];
}

spriteHeight($sprite) {
  height: $sprite[5];
}

spritePosition($sprite) {
  background-position: $sprite[2] $sprite[3];
}

spriteImage($sprite) {
  background-image: url($sprite[8]);
}

sprite($sprite) {
  spriteImage($sprite)
  spritePosition($sprite)
  spriteWidth($sprite)
  spriteHeight($sprite)
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of Stylus

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

sprites($spritesheet_sprites);
*/
sprites($sprites) {
  for $sprite in $sprites {
    $sprite_name = $sprite[9];
    .{$sprite_name} {
      sprite($sprite);
    }
  }
}
