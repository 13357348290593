
$unselectable
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

arrow(color, border=1px)
  border: border solid color
  border-right: 0
  border-top: 0
  content: " "
  display: block
  height: 7px
  pointer-events: none
  position: absolute
  transform: rotate(-45deg)
  width: 7px
