@font-face {
    font-family: "gotham-bold";
    src: url('../fonts/gotham/GothamRnd-Bold.otf');
}

@font-face {
    font-family: "gotham-book";
    src: url('../fonts/gotham/GothamRnd-Book.otf');
}

@font-face {
    font-family: "gotham-light";
    src: url('../fonts/gotham/GothamRnd-Light.otf');
}

@font-face {
    font-family: "gotham-medium";
    src: url('../fonts/gotham/GothamRnd-Medium.otf');
}

@font-face {
    font-family: "moon-bold";
    src: url('../fonts/moon/Moon-Bold.otf');
}

@font-face {
    font-family: "moon-light";
    src: url('../fonts/moon/Moon-Light.otf');
}

@font-face {
    font-family: "pier-bold-italic";
    src: url('../fonts/pier/pier-bold-italic.otf');
}

@font-face {
    font-family: "pier-bold";
    src: url('../fonts/pier/pier-bold.otf');
}

@font-face {
    font-family: "pier-italic";
    src: url('../fonts/pier/pier-italic.otf');
}

@font-face {
    font-family: "pier-regular";
    src: url('../fonts/pier/pier-regular.otf');
}
