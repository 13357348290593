.button
  @extends $input-control
  padding: 10px 14px
  text-align: center
  white-space: nowrap
  border-radius radius*2
  strong
    color: inherit
  small
    display: block
    font-size: size-small
    line-height: 1
    margin-top: 5px
  .fa
    line-height: 24px
    margin: 0 -2px
    width: 24px
  &:active
    box-shadow: inset 0 1px 2px rgba(black, 0.2)

button,
input[type="submit"]
  @extends .button

.button--ghost
  border inherit
  background transparent

// Button colors
.button
  for c in button-colors
    name = unquote(c.name)
    color = c.color
    bg = c.bg
    &--{name}:not(.button--ghost)
      color color
      background bg
      border-color transparent

// Buttons hovers hovers
.button
  for c in button-colors
    name = unquote(c.name)
    color = c.color
    bg = c.bg
    &--{name}
      &:hover,
      &:focus
        background darken(bg, 16%)
        color color
        border-color transparent

.button--bigger
  height 60px
  padding 18px 26px
  margin 5px
